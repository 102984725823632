<!--
Calendar implementation for the HTWK Leipzig timetable. Evaluation and display of the individual dates in iCal format.
Copyright (C) 2024 HTWKalender support@htwkalender.de

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import LocaleSwitcher from "./LocaleSwitcher.vue";
import DarkModeSwitcher from "./DarkModeSwitcher.vue";
import { useRoute } from "vue-router";
const { t } = useI18n({ useScope: "global" });

const route = useRoute();

const isDark = ref(true);

const items = computed(() => [
  {
    label: t("createCalendar"),
    icon: "pi pi-fw pi-plus",
    route: "/",
  },
  {
    label: t("editCalendar"),
    icon: "pi pi-fw pi-pencil",
    route: "/edit",
  },
  {
    label: t("rooms"),
    icon: "pi pi-fw pi-angle-down",
    info: "rooms",
    items: [
      {
        label: t("roomFinderPage.roomSchedule"),
        icon: "pi pi-fw pi-hourglass",
        route: "/rooms/occupancy",
      },
      {
        label: t("freeRooms.freeRooms"),
        icon: "pi pi-fw pi-calendar",
        route: "/rooms/free",
      },
    ],
  },
  {
    label: t("faq"),
    icon: "pi pi-fw pi-book",
    route: "/faq",
  },
  {
    label: t("imprint"),
    icon: "pi pi-fw pi-id-card",
    url: "https://www.htwk-leipzig.de/hochschule/kontakt/impressum/",
  },
  {
    label: t("privacy"),
    icon: "pi pi-fw pi-exclamation-triangle",
    url: "https://www.htwk-leipzig.de/hochschule/kontakt/datenschutzerklaerung/",
  },
]);

function handleDarkModeToggled(isDarkVar: boolean) {
  // Do something with isDark value
  // For example, update the root isDark value
  // Assuming the root component has an isDark ref
  isDark.value = isDarkVar;
}
</script>

<template>
  <Menubar :model="items" class="menubar justify-content-between flex-wrap">
    <template #start>
      <router-link v-slot="{ navigate }" :to="`/`" custom>
        <Button severity="secondary" text class="p-0 mx-2" @click="navigate">
          <div alt="Logo" class="logo" />
        </Button>
      </router-link>
    </template>
    <template #item="{ item, props }">
      <router-link
        v-if="item.route"
        v-slot="{ navigate }"
        :to="item.route"
        custom
      >
        <a
          :class="
            route.path == item.route
              ? 'flex align-items-center active'
              : 'flex align-items-center'
          "
          v-bind="props.action"
          :href="item.route"
          @click="navigate"
        >
          <span :class="item.icon" />
          <span class="ml-2 p-menuitem-label">{{ item.label }}</span>
        </a>
      </router-link>
      <a
        v-else-if="item.url"
        :class="
          route.path.includes(item.info)
            ? 'flex align-items-center active'
            : 'flex align-items-center'
        "
        v-bind="props.action"
        :href="item.url"
      >
        <span :class="item.icon" />
        <span class="ml-2 p-menuitem-label">{{ item.label }}</span>
      </a>
      <span
        v-else
        :class="
          route.path.includes(item.info)
            ? 'flex align-items-center active'
            : 'flex align-items-center'
        "
        v-bind="props.action"
      >
        <span :class="item.icon" />
        <span class="ml-2 p-menuitem-label">{{ item.label }}</span>
      </span>
    </template>
    <template #end>
      <div class="flex align-items-stretch justify-content-center">
        <DarkModeSwitcher
          @dark-mode-toggled="handleDarkModeToggled"
        ></DarkModeSwitcher>
        <LocaleSwitcher></LocaleSwitcher>
      </div>
    </template>
  </Menubar>
</template>

<style scoped>
.menubar {
  background-color: transparent;
  border: none;
}

.logo {
  background-color: var(--primary-color);
  -webkit-mask: url(../../htwk-mask.svg) no-repeat center/contain;
  mask: url(../../htwk-mask.svg) no-repeat center/contain;
  width: 50px;
  height: 50px;
  border-radius: 6px;
}

:deep(.p-submenu-list) {
  border-radius: 6px;
}

:deep(.p-menuitem-link .p-menuitem-label::after) {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: var(--primary-color);
  transition: width 0.3s;
}

:deep(.p-menuitem-link.active .p-menuitem-label::after) {
  width: 100%;
}
</style>
