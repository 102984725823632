<!--
Calendar implementation for the HTWK Leipzig timetable. Evaluation and display of the individual dates in iCal format.
Copyright (C) 2024 HTWKalender support@htwkalender.de

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
-->

<script lang="ts" setup>
import MenuBar from "./components/MenuBar.vue";
import { RouteRecordName, RouterView, useRoute, useRouter } from "vue-router";
import { useHead, useServerHead, useServerSeoMeta } from "@unhead/vue";
import CalendarPreview from "./components/CalendarPreview.vue";
import moduleStore from "./store/moduleStore.ts";
import { computed, provide, ref } from "vue";
import { VueQueryDevtools } from "@tanstack/vue-query-devtools";
import { useI18n } from "vue-i18n";
const { t } = useI18n({ useScope: "global" });

const disabledPages = [
  "room-finder",
  "faq",
  "imprint",
  "privacy-policy",
  "edit",
  "edit-calendar",
  "rooms",
  "free-rooms",
  "room-schedule",
];

// Provide canonical link for SEO
const router = useRouter();
const route = useRoute();

const domain = "cal.htwk-leipzig.de";
provide("domain", domain);
const baseUri = "https://" + domain;
const canonical = computed(
  () =>
    `${baseUri}${router.resolve(route.name ? { name: route.name } : route).path}`,
);
const title = computed(() =>
  route.meta.label
    ? `HTWKalender - ${t(String(route.meta.label))}`
    : "HTWKalender",
);
const description = computed(() =>
  route.meta.description ? t(String(route.meta.description)) : t("description"),
);

useHead({
  title: title,
  link: [{ rel: "canonical", href: canonical }],
  meta: [
    { name: "description", content: description },
    { property: "og:description", content: description },
  ],
});

// SEO optimization
useServerHead({
  title: title,
});
useServerSeoMeta({
  title: title,
  description: description,
  keywords:
    "HTWK Leipzig, Stundenplan, iCal, freie Räume, Lerngruppen, Sport, Prüfungen",
  // openGraph
  ogTitle: title,
  ogDescription: description,
  ogImage: `${baseUri}/img/banner-image.png`,
  ogImageType: "image/png",
  ogLocale: "de_DE",
  ogUrl: canonical,
  // twitter
  twitterCard: "summary_large_image",
  twitterSite: "@HTWKLeipzig",
});

const store = moduleStore();
const mobilePage = ref(true);
provide("mobilePage", mobilePage);

const isDisabled = (routeName: RouteRecordName | null | undefined) => {
  return !disabledPages.includes(routeName as string) && store.modules.size > 0;
};

const updateMobile = () => {
  if (import.meta.env.SSR) return;
  mobilePage.value = window.innerWidth <= 992;
};

updateMobile();

if (!import.meta.env.SSR) window.addEventListener("resize", updateMobile);
</script>

<template>
  <MenuBar />
  <RouterView v-slot="{ Component, route: currentRoute }" class="mb-8">
    <transition name="scale" mode="out-in">
      <div :key="currentRoute.name ?? ''" class="origin-near-top">
        <component :is="Component" />
      </div>
    </transition>
  </RouterView>
  <!-- show CalendarPreview but only on specific router views -->
  <CalendarPreview v-if="isDisabled(route.name)" />

  <VueQueryDevtools />
  <Toast />
</template>

<style scoped>
.scale-enter-active,
.scale-leave-active {
  transition: all 0.2s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

.origin-near-top {
  transform-origin: center 33vh;
}

@media (prefers-reduced-motion) {
  .scale-enter-active,
  .scale-leave-active {
    transition: none;
  }
}
</style>
